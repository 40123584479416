import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import catalog from '../config/catalog'
import Image from '../components/Lendis/Image'
import Button from '../components/Share/Button'
import CheckBox from '../components/Share/CheckBox'
import Header from '../components/Header'
import GoogleTagManagerService from '../services/GoogleTagManagerService'
import useDebounce from '../hooks/use-debounce'
import CatalogOverview from '../components/Catalog/CatalogOverview'
import Cookies from 'js-cookie'

const CatalogPage = props => {
  const { location } = props
  const request = location.pathname.replace('/kataloge/', '').replace(/\/+$/, '')
  const urlParams = new URLSearchParams(location.search)

  const gtm = new GoogleTagManagerService()
  const data = Object(catalog).find(item => item.Name === request)

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false)
  const [agb, setAgb] = useState(false)
  const [openNewsletter, setOpenNewsletter] = useState(false)
  const [company, setCompany] = useState('')
  const [companyList, setCompanyList] = useState([])
  const [isCalledFromSelection, setIsCalledFromSelection] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const debouncedSearchTerm = useDebounce(company, 500)
  const [hasRecentlySignedUp] = useState(Cookies.get('hasRecentlySignedUp') === 'yes')

  useEffect(() => {
    if (Cookies.get('hasRecentlySignedUp') === 'yes' && data) {
      setIsSubmissionSuccessful(true)
      document.getElementById('download-link').click()
    }
  }, [data])

  useEffect(() => {
    if (company && !isCalledFromSelection) {
      setIsSearching(true)
    }
  }, [company, isCalledFromSelection])

  useEffect(() => {
    if (firstName && lastName) {
      setEmail(firstName.toLowerCase() + '.' + lastName.toLowerCase() + '@')
    }
  }, [firstName, lastName])

  useEffect(() => {
    if (debouncedSearchTerm && !isCalledFromSelection) {
      setIsSearching(true)
      async function fetchData() {
        const response = await fetch(
          process.env.COMPANY_SUGGEST_WEBHOOK_URL + encodeURIComponent(debouncedSearchTerm),
        )
        const data = await response.json()
        setCompanyList(data.companies)
        setIsSearching(false)
      }
      fetchData()
    } else {
      setCompanyList([])
    }
  }, [debouncedSearchTerm, company, isCalledFromSelection])

  if (!data) {
    return <CatalogOverview />
  }

  const selectCompany = company => {
    setIsSearching(false)
    setCompanyList([])
    setIsCalledFromSelection(true)
    setCompany(company.name)
  }

  const handleCheckBox = async e => {
    setAgb(!agb)
  }

  const handleOpenNewsletterCheckBox = async e => {
    setOpenNewsletter(!openNewsletter)
  }

  const onSubmit = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const url = window.location.href
    const referrer = document.referrer
    const utmParams = {
      utm_source: Cookies.get('utm_source') || null,
      utm_medium: Cookies.get('utm_medium') || null,
      utm_campaign: Cookies.get('utm_campaign') || null,
      utm_content: Cookies.get('utm_content') || null,
      utm_term: Cookies.get('utm_term') || null,
      gclid: Cookies.get('gclid') || null,
      adgroupid: Cookies.get('adgroupid') || null,
    }
    const payload = {
      email,
      firstName,
      lastName,
      company,
      url,
      ...utmParams,
      referrer,
      phone,
      agb,
      openNewsletter,
    }
    for (let [key, value] of formData.entries()) {
      payload[key] = value
    }

    fetch(e.target.action, {
      method: 'POST',
      body: JSON.stringify(payload),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        setIsSubmitted(true)
        if (res.ok) {
          gtm.fireMQLEvent()
          Cookies.set('hasRecentlySignedUp', 'yes', {
            expires: 30,
            domain: process.env.COOKIE_DOMAIN,
          })
          setIsSubmissionSuccessful(true)
          setIsSubmitted(false)
          if (data['Pdf']) {
            document.getElementById('download-link').click()
          }
        } else {
          setIsSubmissionSuccessful(false)
        }
      })
      .catch(_ => {
        setIsSubmitted(true)
        setIsSubmissionSuccessful(false)
      })
  }

  return (
    <Layout>
      <Header page={data['Header']} />
      <Menu />
      <div className="bg-lendis-gray-gradient">
        <div className=" md:mb-16 pt-32">
          <div className="container md:flex md:flex-wrap mx-auto md:items-center">
            <div className="bg-lendis-gray-gradient pl-8 pr-8 pt-16 md:w-5/12 z-10">
              <h1 className="font-semibold leading-tight text-4xl lg:mb-4">{data['Label']}</h1>
              <p
                className={`transition-ease-2 ${
                  isSubmissionSuccessful ? 'opacity-0' : 'opacity-100'
                }`}
              >
                Für den Katalogdownload benötigen wir nur noch Ihre geschäftliche E-Mail-Adresse und
                Ihren Namen.
              </p>
              <div
                className={`transition-ease-2 ${
                  isSubmissionSuccessful ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <div>
                  {hasRecentlySignedUp ? (
                    <p>
                      Willkommen zurück!
                      <br />
                      <br />
                      Der Katalogdownload beginnt in Kürze,
                      <br />
                      falls nicht klicken Sie{' '}
                      <a
                        id="download-link"
                        className="text-lendis-main"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={data['AC_Field_Cataloglink']}
                      >
                        hier
                      </a>
                      .
                    </p>
                  ) : (
                    <p>
                      Wir freuen uns über Ihr Interesse an unseren Produkten! Eine Email mit dem
                      Download-Link zum Katalog ist bereits auf dem Weg zu Ihnen.
                    </p>
                  )}
                </div>
              </div>
              <div
                className={`transition-ease-2 ${
                  isSubmissionSuccessful ? 'opacity-0' : 'opacity-100'
                }`}
              >
                <form
                  className="flex mt-4"
                  action={process.env.FORM_WEBHOOK_URL}
                  onSubmit={onSubmit}
                  method="post"
                >
                  <div className="flex flex-wrap">
                    <div className="w-1/2 pr-2">
                      <input
                        placeholder="Vorname"
                        value={firstName}
                        type="text"
                        required
                        onChange={e => setFirstName(e.target.value)}
                        className="h-12 mb-10 p-4 w-full py-3 "
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <input
                        placeholder="Nachname"
                        value={lastName}
                        type="text"
                        required
                        onChange={e => setLastName(e.target.value)}
                        className="h-12 mb-10 p-4 w-full py-3 "
                      />
                    </div>
                    <div className="w-full relative mb-10">
                      <input
                        placeholder="Firmenname"
                        value={company}
                        type="text"
                        required
                        onChange={e => {
                          setIsCalledFromSelection(false)
                          setCompany(e.target.value)
                        }}
                        className="h-12 p-4 w-full py-3 "
                      />
                      <div className="absolute mt-2 left-0 right-0 bg-white rounded-b-lg shadow-xl z-40">
                        {isSearching ? (
                          <div className="px-4 py-2 text-gray-800 ">Suche ...</div>
                        ) : null}
                        {companyList.map((company, index) => (
                          <div key={'company_' + index} className="border-b-2">
                            <span
                              className="block px-4 py-2 text-gray-800 hover:bg-gray-300
                              hover:text-lendis-main-dark cursor-pointer"
                              onClick={() => selectCompany(company)}
                              onKeyPress={() => selectCompany(company)}
                              role="button"
                              tabIndex="-1"
                            >
                              {company.name}
                            </span>
                            <span className="block px-4 py-2 text-lendis-usp">{company.city}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="w-full">
                      <input
                        placeholder="Geschäftliche E-Mail-Adresse"
                        title="Bitte geben Sie eine gültige E-Mail-Adresse an."
                        pattern="^([a-zA-Z0-9+_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                        value={email}
                        type="text"
                        required
                        onChange={e => setEmail(e.target.value)}
                        className="h-12 mb-10 p-4 w-full py-3 "
                      />
                    </div>
                    <div className="w-full">
                      <input
                        placeholder="Telefonnummer (optional)"
                        value={phone}
                        type="text"
                        onChange={e => setPhone(e.target.value)}
                        className="h-12 mb-10 p-4 w-full py-3 "
                      />
                    </div>
                    <div className="w-full">
                      <CheckBox
                        smallLabel={true}
                        Label={
                          'Ich bin damit einverstanden, dass die Lendis GmbH mir Informationen zu ihrem Produktsortiment per E-Mail zuschickt.'
                        }
                        Value={agb}
                        Required={true}
                        OnChange={e => handleCheckBox(e)}
                      />
                    </div>
                    <div className="w-full">
                      <CheckBox
                        smallLabel={true}
                        Label={
                          'Ja, ich möchte regelmäßig <b>exklusive Rabatte, Tipps und Fachartikel</b> zu Büroausstattung per E-Mail erhalten.'
                        }
                        value={openNewsletter}
                        OnChange={e => handleOpenNewsletterCheckBox(e)}
                      />
                    </div>
                    <div className="w-full">
                      <div>
                        Mir ist bewusst, dass ich meine Einwilligung gegenüber der Lendis GmbH
                        jederzeit widerrufen kann.
                      </div>
                    </div>
                    <div
                      className={`text-red-500 transition-ease-2 w-full ${
                        isSubmitted && !isSubmissionSuccessful ? 'block' : 'hidden'
                      }`}
                    >
                      <p>Fehler beim Versenden. Bitte versuchen Sie es später noch einmal!</p>
                    </div>
                    <div className="w-full text-right">
                      <Button disabled={!agb} text="Download" arrow />
                    </div>
                  </div>

                  <input
                    type="hidden"
                    name="activeCampaignListId"
                    value={data['ActiveCampaignListId']}
                  />
                  <input type="hidden" name="form_id" value={data['ActiveCampaignListId']} />
                  <input type="hidden" name="form_name" value="Catalog From" />
                  <input type="hidden" name="section" value={urlParams.get('section') || ''} />
                  <input type="hidden" name="catalog" value={data['Name']} />
                  <input type="hidden" name="ac_field_vertical" value={data['AC_Field_Vertical']} />
                  <input type="hidden" name="ac_field_category" value={data['AC_Field_Category']} />
                  <input type="hidden" name="ac_field_product" value={data['AC_Field_Product']} />
                  <input
                    type="hidden"
                    name="ac_field_cataloglink"
                    value={data['AC_Field_Cataloglink']}
                  />
                  <input type="hidden" name="ac_tag_product" value={data['AC_Tag_Product']} />
                </form>
              </div>
            </div>
            <div className="flex items-center justify-center relative text-center md:w-7/12 z-10 md:pt-20 md:pb-16 md:pt-0 pt-16">
              <div className="relative">
                <Image src={data['Image']} size="1/2" alt={data['Name']} />
              </div>
            </div>
          </div>
          <div className=" bg-lendis-gray-gradient flex justify-end md:absolute md:float-right md:w-full z-0 md:top-0">
            <div className="bg-lendis-main h-20 w-1/2 md:h-256" />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default CatalogPage
