import React from 'react'
import Layout from '../layout'
import Header from '../Header'
import Menu from '../Menu'
import BreadCrumb from '../Share/BreadCrumb'
import UserCatalog from '../Home/UserCatalog'
import Footer from '../Footer'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Kataloge',
  },
]

const CatalogOverview = () => {
  const back = process.env.CLOUDINARY_URL + '/f_auto/website/2b_1920px_darker_DSC5520_owso1o.jpg'

  return (
    <Layout>
      <Header page="catalog-overview" />
      <Menu />
      <div
        className="big-background-image pb-8 bg-center bg-lendis-black bg-no-repeat bg-top lg:pb-40 pt-40 px-3"
        style={{ backgroundImage: `url(${back})` }}
      >
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">Kataloge</h1>
          <p className="font-semibold lg:text-2xl mb-10 mb-5 px-8 text-xl ">
            Unser gesamtes Produktangebot bequem im Katalog kennenlernen
          </p>
        </div>
        <div className="bg-white container mx-auto lg:px-10 ">
          <BreadCrumb breadCrumbItems={breadcrumb} />
        </div>

        <div className="bg-lendis-black container mx-auto pt-16">
          <div className="bg-lendis-black md:pb-16">
            <div className="md:flex  justify-center px-3 lg:px-16">
              <div className="bg-lendis-black">
                <UserCatalog />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
export default CatalogOverview
