import React from 'react'
import { Link } from 'gatsby'
import Image from '../Lendis/Image'
import catalog from '../../config/catalog'

const UserCatalog = () => {
  return (
    <div className="container mx-auto flex flex-wrap ">
      {catalog.map((item, idx) => {
        const link = item.Link ? item.Link : `/kataloge/${item['Name']}/`
        return (
          <div key={idx} className="lg:w-1/2 lg:px-2 lg:py-3 mx-auto">
            <Link to={link}>
              <div className="relative image-banner-link md:mt-3">
                <div className="relative">
                  <Image className="opacity-50" size="1/2" src={item['Image']} alt={item['Name']} />
                </div>
                <div className="absolute bottom-0 left-0 mb-8 ml-12">
                  <div>
                    <div className="flex items-center mb-3">
                      <p className="mr-4 text-lendis-main">{item['Number']}</p>
                      <hr className="border-t-2 w-20" />
                    </div>
                  </div>
                  <h5 className="text-2xl text-white">{item['Label']}</h5>
                </div>
              </div>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default UserCatalog
